<template>
  <div class="project-sales-lawyer-info p-2">
    <h4 class="mb-3">Lawyer</h4>
    <div v-if="lawyerData" class="row">
      <display-data class="col-6" label="Loan Agreement">
        <p class="font-bold">{{ laLawyer.name }}</p>
        <p>{{ laLawyer.lawyerFirmName }}</p>
        <p>{{ laLawyer.user.email }}</p>
        <p>{{ $getUserPhoneNumber(laLawyer.user) }}</p>
      </display-data>
      <display-data class="col-6" label="Sales Purchase Agreement">
        <p class="font-bold">
          {{ spaLawyer.name }}
        </p>
        <p>{{ spaLawyer.lawyerFirmName }}</p>
        <p>{{ spaLawyer.user.email }}</p>
        <p>{{ $getUserPhoneNumber(spaLawyer.user) }}</p>
      </display-data>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    lawyerData: {
      type: Object
    }
  },
  data: function () {
    return {};
  },
  computed: {
    laLawyer() {
      return this.lawyerData.loanAgreementLawyer;
    },
    spaLawyer() {
      return this.lawyerData.salesPurchaseAgreementLawyer;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
